import { type ComponentPropsWithoutRef } from 'react'
import { Button, Link, Typography } from '@shc/ui'
import NextLink from 'next/link'
import { contentTypeBasePathMap } from '@lib/constants'
import Analytics from '@components/analytics'
import LocationHours from '@app/(main)/locations/_components/location-hours'
import LocationSaveaSpotSummary from '@app/(main)/locations/_components/location-save-a-spot-summary'
import LocationWaitTimes from '@app/(main)/locations/_components/location-wait-times'
import ResponsiveImage from '@components/responsive-image'
import type {
  TypePlacePublic,
  TypeScheduleAmended,
  TypeScheduleException,
} from '@lib/location-types'
import { useBuildSearchContext } from '@lib/analytics'
import { formatInTimeZone } from 'date-fns-tz'
import {
  convertToDisplayReadySchedule,
  getScheduleSummaryInfo,
} from '@lib/utilities/location-hours-utilities'

export type TypePlaceHit = {
  wait_time: string | null
  wait_time_status: string | null
  first_available_spot: number | null
} & TypePlacePublic

export interface LocationSearchResultProps extends ComponentPropsWithoutRef<'article'> {
  hit: TypePlaceHit
}

const LocationSearchResult = ({ hit }: LocationSearchResultProps) => {
  const {
    name,
    description,
    formatted_address: address,
    phone,
    phone_formatted,
    id: placeId,
    slug,
    schedules: schedule,
    schedule_notes: scheduleNotes,
    schedule_exceptions: scheduleExceptions,
    wait_time: waitTime,
    wait_time_status: waitTimeStatus,
    scheduling_status: schedulingStatus,
    first_available_spot: firstAvailableSpot,
    scheduling_type: schedulingType,
    scheduling_cta_label: scheduleCtaLabel,
    photo_url: photoUrl,
    place_type_name: placeType,
    contains_places: containsPlaces,
    url: placeUrl,
  } = hit

  const currentPacificTime = formatInTimeZone(
    new Date(),
    'America/Los_Angeles',
    'yyyy-MM-dd HH:mm:ss'
  )
  const displayReadySchedule = convertToDisplayReadySchedule(
    currentPacificTime,
    hit.schedules as TypeScheduleAmended[],
    hit.schedule_exceptions as TypeScheduleException[]
  )
  const locationHoursSummary = getScheduleSummaryInfo(currentPacificTime, displayReadySchedule)

  const isLocationOpen =
    locationHoursSummary.operationalStatus === 'Open' ||
    locationHoursSummary.operationalStatus === 'Open 24 hours'

  const showWaitTimeStatus =
    isLocationOpen && waitTimeStatus === 'OPEN' && !!waitTime && waitTime !== 'N/A'

  const getLinkForLocation = () => {
    if (slug) return `${contentTypeBasePathMap['place']}${slug}`

    if (placeUrl) return placeUrl

    return ''
  }

  const link = getLinkForLocation()

  const locationPhotoUrl = photoUrl?.includes('images.ctfassets.net')
    ? photoUrl?.replace(/^https?:/i, '')
    : ''

  const imageInfo = {
    altTag: name,
    urlString: locationPhotoUrl,
  }

  const snowplowSearchContext = useBuildSearchContext()

  return (
    <article>
      <div className="flex md:gap-5 lg:gap-15 items-center justify-between">
        <div className="flex flex-col gap-3 justify-between">
          {name && link && (
            <Analytics
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: name,
                    component_url: link,
                  },
                },
                snowplowSearchContext,
                {
                  name: 'place',
                  data: {
                    place_name: name ?? '',
                    place_id: placeId ?? -1,
                  },
                },
              ]}>
              <Typography variant="h4" as="h2">
                <Link
                  as={NextLink}
                  href={link}
                  noUnderline
                  className="hover:underline"
                  target={!slug && placeUrl ? '_blank' : undefined}>
                  {name}
                </Link>
              </Typography>
            </Analytics>
          )}
          {name && !link && (
            <Typography as={'h2'} variant="h4">
              {name}
            </Typography>
          )}
          {description && <div>{description}</div>}
          {schedule && schedule.length > 0 && (
            <LocationHours
              now={currentPacificTime}
              schedule={schedule}
              scheduleNotes={scheduleNotes}
              scheduleExceptions={scheduleExceptions}
              summaryOnly={true}
            />
          )}
          {address && (
            <address className="not-italic">
              <div>{address.replace(/\n/g, ', ')}</div>
              <Analytics
                click={{
                  name: 'component_click',
                  data: {},
                }}
                contexts={[
                  {
                    name: 'component',
                    data: {
                      component_text: 'Get directions',
                      component_url: `https://google.com/maps/search/?api=1&query=${name}`,
                    },
                  },
                  snowplowSearchContext,
                  {
                    name: 'place',
                    data: {
                      place_name: name ?? '',
                      place_id: placeId ?? -1,
                    },
                  },
                ]}>
                <Link
                  href={`https://google.com/maps/search/?api=1&query=${name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Get directions"
                  noUnderline>
                  Get directions
                </Link>
              </Analytics>
            </address>
          )}
          {placeType?.toLowerCase().includes('hospital') ? (
            <div>
              <Analytics
                click={{
                  name: 'component_click',
                  data: {},
                }}
                contexts={[
                  {
                    name: 'component',
                    data: {
                      component_text: phone_formatted ?? '',
                      component_url: `tel:${phone}`,
                    },
                  },
                  snowplowSearchContext,
                  {
                    name: 'place',
                    data: {
                      place_name: name ?? '',
                      place_id: placeId ?? -1,
                    },
                  },
                ]}>
                <Link noUnderline href={`tel:${phone}`}>
                  {phone_formatted}
                </Link>
              </Analytics>
            </div>
          ) : (
            <>
              {phone && !containsPlaces?.[0] && (
                <div>
                  <Analytics
                    click={{
                      name: 'component_click',
                      data: {},
                    }}
                    contexts={[
                      {
                        name: 'component',
                        data: {
                          component_text: phone_formatted ?? '',
                          component_url: `tel:${phone}`,
                        },
                      },
                      snowplowSearchContext,
                      {
                        name: 'place',
                        data: {
                          place_name: name ?? '',
                          place_id: placeId ?? -1,
                        },
                      },
                    ]}>
                    <Link noUnderline href={`tel:${phone}`}>
                      {phone_formatted}
                    </Link>
                  </Analytics>
                </div>
              )}
              {containsPlaces?.[0] && (
                <div>
                  <Analytics
                    click={{
                      name: 'component_click',
                      data: {},
                    }}
                    contexts={[
                      {
                        name: 'component',
                        data: {
                          component_text: 'View phone directory',
                          component_url: `${link}#departments`,
                        },
                      },
                      snowplowSearchContext,
                      {
                        name: 'place',
                        data: {
                          place_name: name ?? '',
                          place_id: placeId ?? -1,
                        },
                      },
                    ]}>
                    <Link noUnderline as={NextLink} href={`${link}#departments`}>
                      View phone directory
                    </Link>
                  </Analytics>
                </div>
              )}
            </>
          )}
          {showWaitTimeStatus && (
            <LocationWaitTimes waitTimeStatus={waitTimeStatus} waitTime={waitTime} />
          )}

          {schedulingStatus === 'enabled' && (
            <>
              {/* If firstAvailableSpot strores a valid date, Algolia returns it as non-zero Unix epoch time  */}
              {!!firstAvailableSpot && <LocationSaveaSpotSummary timeslot={firstAvailableSpot} />}

              {/* Display button for save a spot, lab appointment, or on my way */}
              {/* firstAvailableSpot === 0 corresponds to an error state, in which case we still want to show scheduling button */}

              {(firstAvailableSpot ||
                firstAvailableSpot === 0 ||
                (schedulingType && ['CTA only'].includes(schedulingType))) && (
                <div className="pt-1">
                  <Button
                    as="a"
                    href={link}
                    variant="outlined"
                    size="sm"
                    width="full"
                    className="w-full md:w-fit"
                    data-testid="cta-button">
                    {scheduleCtaLabel}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col justify-end items-center">
          {locationPhotoUrl && !link && (
            <ResponsiveImage
              image={imageInfo}
              focalPoint="center"
              columnWidthMax="100%"
              className="md:w-[288px] md:h-[162px] lg:w-[335px] lg:h-[188px] rounded"
            />
          )}
          {locationPhotoUrl && link && (
            <Analytics
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: `${name} [Image]`,
                    component_url: link,
                  },
                },
                snowplowSearchContext,
                {
                  name: 'place',
                  data: {
                    place_name: name ?? '',
                    place_id: placeId ?? -1,
                  },
                },
              ]}>
              <NextLink href={link} className="group rounded overflow-hidden block">
                <ResponsiveImage
                  image={imageInfo}
                  focalPoint="center"
                  columnWidthMax="100%"
                  className="md:w-[288px] md:h-[162px] lg:w-[335px] lg:h-[188px] rounded scale-100 duration-200 group-hover:scale-105 ease-in-out"
                />
              </NextLink>
            </Analytics>
          )}
        </div>
      </div>
    </article>
  )
}

export default LocationSearchResult
