import SearchHeroLoading from '@app/(main)/search/_components/skeleton-loaders/search-hero-loading'
import SearchNavLoading from '@app/(main)/search/_components/skeleton-loaders/search-nav-loading'
import { Container, Divider, Skeleton } from '@shc/ui'
import { Fragment } from 'react'

export const LocationSearchSideLoader = () => (
  <div className="hidden xl:block w-[306px] space-y-4">
    <Skeleton height={24} width={80} />
    {[...Array(5)].map((_, i) => (
      <div key={i} className="flex gap-3 items-center">
        <Skeleton height={20} width={20} />
        <Skeleton height={16} width={140} />
      </div>
    ))}
    <Skeleton height={16} width={72} />
    <Divider />

    <Skeleton height={24} width={120} />
    {[...Array(3)].map((_, i) => (
      <div key={i} className="flex gap-3 items-center">
        <Skeleton height={20} width={20} />
        <Skeleton height={16} width={190} />
      </div>
    ))}
    <Divider />

    <Skeleton height={24} width={82} />
    {[...Array(4)].map((_, i) => (
      <div key={i} className="flex gap-3 items-center">
        <Skeleton height={20} width={20} />
        <Skeleton height={16} width={150} />
      </div>
    ))}
    <Skeleton width={72} height={16} />
    <Divider />

    <Skeleton width={108} height={24} />
    <Skeleton width={266} height={40} />
    {[...Array(6)].map((_, i) => (
      <div key={i} className="flex gap-3 items-center">
        <Skeleton height={20} width={20} />
        <Skeleton height={16} width={130} />
      </div>
    ))}
    <Divider />
  </div>
)

export const LocationSearchContentLoader = () => (
  <div className="flex-grow space-y-8">
    <div className="flex flex-col gap-8">
      <Skeleton height={40} className="xl:hidden w-full md:!w-40 rounded-full" /> {/* Button */}
      <Skeleton height={24} width={235} /> {/* Result Count */}
    </div>
    {[...Array(4)].map((_, i) => (
      <Fragment key={i}>
        <Divider />
        <div className="flex gap-4 md:gap-8 justify-between items-center">
          <div>
            <Skeleton height={24} width={285} className="mb-6" />
            <Skeleton height={16} width={235} className="mb-6" />
            <Skeleton height={16} width={235} className="mb-4" />
            <Skeleton height={16} width={200} />
          </div>
          {!(i % 2) && <Skeleton height={185} width={335} className="hidden md:block" />}
        </div>
      </Fragment>
    ))}
  </div>
)

export default function LocationSearchLoading() {
  return (
    <>
      <SearchHeroLoading />
      <SearchNavLoading />
      <Container className="flex gap-x-10 py-8 xl:py-15">
        <LocationSearchSideLoader />
        <LocationSearchContentLoader />
      </Container>
    </>
  )
}
