'use client'

import { type ComponentPropsWithoutRef, useState } from 'react'
import clsx from 'clsx'
import { sortBy, uniqBy } from 'lodash'
import slugify from '@sindresorhus/slugify'
import { Badge, Button, Container, Icon, Link, Typography } from '@shc/ui'
import {
  Configure,
  useCurrentRefinements,
  useHits,
  useInstantSearch,
  usePagination,
} from 'react-instantsearch'

import { doScroll } from '@hooks/useCustomScroll'
import { locationFacetConfigMap } from '@app/(main)/search/_utils/constants'
import { LocationSearchContentLoader } from '@app/(main)/search/_components/skeleton-loaders/location-search-loading'
import { useSharedSearchContext } from '@app/(main)/search/_contexts/search-context-wrapper'
import BlockRenderer from '@cms/renderers/block-renderer'
import Drawer from '@components/drawer'
import EmptyState from '@app/(main)/search/_components/empty-state'
import LocationSearchResult, {
  type TypePlaceHit,
} from '@app/(main)/search/_components/location-search-result'
import Pagination from '@components/pagination'
import SearchError from '@app/(main)/search/_components/search-error'
import SearchFacetMenu from '@components/search-facet-menu'
import SearchHero from '@app/(main)/search/_components/search-hero'
import SearchNav from '@app/(main)/search/_components/search-nav'
import SearchResultsSummary from '@app/(main)/search/_components/search-results-summary'
import type { TypeSearchPromo } from '@lib/generated-types'
import SearchAnalyticsTracker from '@app/(main)/search/_components/search-analytics-tracker'
import useAnalytics from '@hooks/use-analytics'

interface LocationSearchProps extends ComponentPropsWithoutRef<'div'> {
  promos: Record<string, TypeSearchPromo>
}

const PlaceResults = () => {
  const { status: instantSearchStatus } = useInstantSearch()
  const { items } = useHits<TypePlaceHit>()
  const showLoadState = instantSearchStatus === 'stalled'
  const showNoResultsState = !showLoadState && items.length === 0

  if (showNoResultsState) return

  return (
    <section className="flex flex-col gap-8 lg:gap-10">
      <ul className="list-none flex flex-col">
        {items.map((item) => {
          return (
            <li key={item.objectID} className="py-8 border-t last:border-b">
              <LocationSearchResult hit={item} />
            </li>
          )
        })}
      </ul>
    </section>
  )
}

// eslint-disable-next-line @next/next/no-async-client-component
export default function SearchLocationIndex({
  promos: availablePromos,
}: Readonly<LocationSearchProps>) {
  /* Algolia hooks */
  const { results, status: instantSearchStatus } = useInstantSearch()
  const { nbHits, currentRefinement, nbPages, refine: refinePagination } = usePagination()
  const currentRefinements = useCurrentRefinements()

  /* Contexts */
  const { selectedLocation } = useSharedSearchContext()

  /* State variables */
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false)

  /* Derived variables */
  const facets = results.renderingContent?.facetOrdering?.facets?.order ?? []
  const showErrorState = instantSearchStatus === 'error'
  const showLoadState = instantSearchStatus === 'stalled'
  // see Handling no results in Algolia: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-display/react/#handling-no-results
  const showNoResultsState = !results.__isArtificial && results.nbHits === 0
  const showResults = !showErrorState && !showNoResultsState
  const showPagination = showResults && nbPages > 1
  const showRefinements = currentRefinements.items?.length > 0

  /* Promo vars */
  const activePromos = uniqBy(results.userData, 'internalName') ?? []
  const hasAvailablePromos = Object.keys(availablePromos).length > 0
  // sort availablePromos by variant, standard first
  const sortedAvailablePromos = hasAvailablePromos
    ? sortBy(availablePromos, (promo) =>
        promo?.fields?.blocks?.[0]?.fields?.variant === 'standard' ? 0 : 1
      )
    : []

  const renderPromos = () => {
    if (!hasAvailablePromos || activePromos.length < 1) return null

    // Keep only the promos that are active according to the rules set in Algolia and the search criteria
    const filteredPromos = sortedAvailablePromos.filter((promo) => {
      const promoInternalName = promo?.fields?.internalName ?? ''
      return activePromos.some((activePromo) => activePromo.internalName === promoInternalName)
    })

    // Display sorted, filtered promos
    return filteredPromos.map((data) => (
      <div key={slugify(data.fields.internalName)}>
        {data?.fields?.blocks?.map((block) => (
          <BlockRenderer key={block.sys.id} block={block} />
        ))}
      </div>
    ))
  }

  const { track } = useAnalytics()

  // Handlers
  const onPageChange = (page: number) => {
    refinePagination(page)
    doScroll(0)
  }
  return (
    <>
      {/* Algolia InstantSearch configuration */}
      <Configure
        {...(selectedLocation
          ? {
              aroundLatLngViaIP: false,
              aroundLatLng: `${selectedLocation?.lat},${selectedLocation?.lng}`,
            }
          : { aroundLatLngViaIP: true })}
      />

      <Drawer
        isDrawerOpen={isFilterMenuOpen}
        setIsDrawerOpen={setIsFilterMenuOpen}
        activePathname="/search/locations"
        label="Search filters"
        labelledBy="search-filter-button"
        eventPrefix="filter_drawer">
        <div
          className="flex flex-row items-start justify-between border-b mb-5"
          data-testid="menu-drawer-header">
          <Typography variant="h5" className="w-full pb-5 text-left">
            Filters
          </Typography>
        </div>
        <SearchFacetMenu facets={facets} facetConfigMap={locationFacetConfigMap} />
      </Drawer>

      <SearchAnalyticsTracker />
      <SearchHero title="Locations" searchPlaceholderText="Search by location name or keyword" />
      <SearchNav />

      {/* Results Container */}
      <Container className="py-8 xl:py-15">
        <div className="flex flex-rows gap-x-10">
          {/* LEFT COLUMN - FACETS*/}
          <div className="hidden xl:flex flex-col pr-4 w-[306px] min-w-[306px]">
            <SearchFacetMenu facets={facets} facetConfigMap={locationFacetConfigMap} />
          </div>

          {/* RIGHT COLUMN - RESULTS */}
          <div className={clsx('flex flex-col flex-grow', showResults && 'gap-5 md:gap-8')}>
            {/* RESULTS SUMMARY, SORT+FILTER BUTTON */}
            <div className="flex flex-col-reverse justify-start xl:flex-row xl:justify-between gap-5 md:gap-8">
              {/* results summary */}
              {showResults && <SearchResultsSummary />}

              {/* Filter button - hide on xl */}
              {(showResults || showRefinements) && (
                <Button
                  id="search-filter-button"
                  aria-label="Open search filters"
                  className={clsx('xl:hidden', showNoResultsState && 'mb-5 md:mb-8')}
                  size="sm"
                  width="md"
                  startDecorator={<Icon icon="sliders-simple" />}
                  endDecorator={
                    currentRefinements.items.length > 0 && (
                      <Badge>
                        {currentRefinements.items.reduce(
                          (total, item) => total + item.refinements.length,
                          0
                        )}
                      </Badge>
                    )
                  }
                  onClick={() => {
                    setIsFilterMenuOpen(true)

                    // button click event
                    track({
                      event: { name: 'component_click', data: {} },
                      contexts: [{ name: 'component', data: { component_text: 'Filters' } }],
                    })

                    // modal open event
                    track({
                      event: { name: 'modal_open', data: {} },
                      contexts: [
                        {
                          name: 'modal',
                          data: { modal_name: 'Locations search filter menu' },
                        },
                      ],
                    })
                  }}>
                  Filters
                </Button>
              )}
            </div>

            {/* ERROR */}
            {showErrorState && <SearchError />}

            {/* LOADING */}
            {showLoadState && <LocationSearchContentLoader />}

            {/* NO RESULTS */}
            {showNoResultsState && (
              <EmptyState data-testid="no-results-state">
                <Typography variant="h2">No locations matched your search term</Typography>
                <Typography variant="body">
                  Adjust the filters, try a different search term or select another search category.
                </Typography>
                <Typography variant="body">
                  Need help?&nbsp;
                  <Link href="/contact-us">Contact us</Link>
                </Typography>
              </EmptyState>
            )}

            {/* PROMOS & RESULTS */}
            <div className="flex flex-col">
              {/* PROMOS */}
              {renderPromos()}

              {/* RESULTS */}
              <PlaceResults />
            </div>

            {/* PAGINATION */}
            {showPagination && (
              <Pagination
                className="" // Need empty classname to let flex control spacing
                totalItems={Math.min(nbPages * results.hitsPerPage, nbHits)}
                itemsPerPage={results.hitsPerPage}
                currentPageNumber={currentRefinement + 1}
                pageClickFunction={onPageChange}
                pageIndexStart={0}
              />
            )}
          </div>
        </div>
      </Container>
    </>
  )
}
