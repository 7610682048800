import { type ComponentPropsWithoutRef } from 'react'
import { Icon } from '@shc/ui'

export interface LocationWaitTimesProps extends ComponentPropsWithoutRef<'div'> {
  waitTime: string
  waitTimeStatus: string
}

const LocationWaitTimes = ({
  waitTimeStatus,
  waitTime,
  className,
  ...props
}: LocationWaitTimesProps) => {
  return (
    <div className={className} {...props}>
      <div className="flex flex-row items-center gap-2">
        <Icon fixedWidth icon="person-walking" className="h-4 text-gray-700 text-sm" />
        <div className="text-gray-700 font-semibold">Walk-in wait time</div>
      </div>
      <div className="ml-7">{waitTimeStatus === 'OPEN' && waitTime}</div>
    </div>
  )
}

export default LocationWaitTimes
